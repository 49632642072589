import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Home from './components/Home';
import Main from './components/Main';

const App = () => {

  const theme = createMuiTheme({
    palette: {
      primary: { main: '#2e2e2e' },
    },
  });

  return <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/' component={Main} />
      </Switch>
    </BrowserRouter>
  </MuiThemeProvider>
}

export default App;
import React, { useState } from "react";
import { Scrollbars } from 'react-custom-scrollbars';
import Img from 'react-image';
import Dialog from '@material-ui/core/Dialog';
import Footer from '../Footer';
import '../css/Photos.css';
import photosArray from "./PhotosArray";

const Photos = () => {

    const [popup, togglePopup] = useState(false);
    const [selectedImage, selectImage] = useState('');

    return <Scrollbars universal={true}>
        <div id="PhotosWrapper">
            {photosArray.map((e, i) =>
                <div key={i}
                    className="photoWrapper"
                    onClick={() => { if (window.screen.width >= 545) { togglePopup(!popup); selectImage(i); } }}
                    style={{ cursor: window.screen.width >= 545 ? 'pointer' : 'default' }}
                >
                    <div className="photoThumbnail">{e.name}</div>
                    <Img
                        src={e.src}
                        loader={<div className="photoLoading" >
                            <svg viewBox="-2000 -1000 4000 2000">
                                <path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z"></path>
                                <use href="#inf" strokeDasharray="1570 5143" strokeDashoffset="6713px"></use>
                            </svg >
                        </div>}
                        // lazysrc={e.src}
                        height="100%"
                        width="100%"
                        className="media"
                        alt="© Ange Loron"
                    />
                </div>)}
        </div>
        <Footer />
        <Dialog className="dialog" onClose={() => togglePopup(!popup)} open={popup}>
            {selectedImage !== '' && <img className="popupimage" src={photosArray[selectedImage].src} alt="© Ange Loron" />}
        </Dialog>
    </Scrollbars >
}

export default Photos;
import React from "react";
import CameraIcon from '@material-ui/icons/CameraAlt';
import VideoIcon from '@material-ui/icons/Videocam';
import CodeIcon from '@material-ui/icons/Code';
import AboutIcon from '@material-ui/icons/Info';
import AccountIcon from '@material-ui/icons/AccountCircle';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

export const menu_array = [
    { name: 'PHOTOS', icon: <CameraIcon /> },
    { name: 'VIDEOS', icon: <VideoIcon /> },
    { name: 'CODES', icon: <CodeIcon /> },
    { name: 'BLOG', icon: <LibraryBooksIcon /> },
    { name: 'ABOUT', icon: <AboutIcon /> },
    { name: 'CONTACT', icon: <AccountIcon /> },
];
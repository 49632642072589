import React, { useState, useEffect } from "react";
import CustomLink from '../CustomLink.js';
import { menu_array } from "./MenuArray";
import { List, ListItem, ListItemIcon, ListItemText, Icon } from "@material-ui/core";
import HomeIcon from '@material-ui/icons/Home';
import './css/Menu.css';

const MenuDrawer = ({ location, toggleDrawer }) => {

    const [active, toggleActive] = useState('PHOTOS');

    useEffect(x => {
        const currentPage = location.pathname.replace('/main/', '').toUpperCase().replace('_', ' ');
        toggleActive(currentPage);
    }, [location]);

    return <div id="menuDrawer">
        <h1>Ange Loron</h1>

        <List>
            <CustomLink to="/">
                <ListItem button>
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    <ListItemText primary="HOME" />
                </ListItem>
            </CustomLink>
            {menu_array.map((x, i) => <CustomLink location={location} key={i} to={`/${x.name.toLowerCase().replace(' ', '_')}`}>
                <ListItem button onClick={() => toggleDrawer(false)}>
                    <ListItemIcon>
                        <Icon color={active === x.name ? 'primary' : 'inherit'}>
                            {x.icon}
                        </Icon>
                    </ListItemIcon>
                    <ListItemText primary={active === x.name ? <b>{x.name}</b> : x.name} />
                </ListItem>
            </CustomLink>)}
        </List>
    </div>
}

export default MenuDrawer;
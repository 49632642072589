import { useEffect, useState } from 'react'

export const useTypewriter = (originalText = ["This is a test", "Another test"]) => {
    const [_textArray, _setTextArray] = useState(originalText)

    let [commandSet = [], setCommandset] = useState([])

    useEffect(() => {
        let _commandset = []
        _textArray.forEach((set, iSet) => {
            for (let i = 0; i < set.length; i++) {
                _commandset.push({
                    textArrayIndex: iSet,
                    length: i
                })
            }
            for (let i = 0; i < 20; i++) {
                _commandset.push({
                    textArrayIndex: iSet,
                    length: set.length
                })
            }
            for (let i = 0; i < set.length; i++) {
                _commandset.push({
                    textArrayIndex: iSet,
                    length: set.length - i
                })
            }
        })
        setCommandset(_commandset)
    }, [_textArray])

    const [cursorIndex, setCursorIndex] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setCursorIndex(prev => prev === commandSet.length - 1 ? 0 : prev + 1)
        }, 70); //SET THE SPEED HERE
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cursorIndex])

    const currentCursor = commandSet[cursorIndex]
    if (!currentCursor) {
        return ["", _setTextArray]
    }
    const { textArrayIndex, length } = currentCursor

    const textEffect = _textArray[textArrayIndex].substring(0, length)
    return [textEffect, _setTextArray]

}

export default useTypewriter
import React from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ShareIcon from '@material-ui/icons/Share';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Switch, Route } from 'react-router-dom';
import Menu from './Menu';
import MenuDrawer from './MenuDrawer';
import Photos from './Views/Photos';
import Videos from './Views/Videos';
import Codes from './Views/Codes';
import Blog from './Views/Blog';
import About from './Views/About';
import Contact from './Views/Contact';
import './css/Main.css';

const Main = ({ location }) => {

    const [dial, toggleDial] = React.useState(false);
    const [drawer, toggleDrawer] = React.useState(false);

    const actions = [
        {
            icon: <FacebookIcon />,
            name: 'Facebook',
            url: 'https://www.facebook.com/loronange'
        },
        {
            icon: <TwitterIcon />,
            name: 'Twitter',
            url: 'https://twitter.com/ange_loron'
        },
        {
            icon: <InstagramIcon />,
            name: 'Instagram',
            url: 'https://www.instagram.com/angeloronphoto/'
        },
    ];

    return <>
        <Menu location={location} toggleDrawer={toggleDrawer} />
        <div className="mainwrapper">
            <Switch>
                <Route exact path='/photos' component={Photos} />
                <Route exact path='/videos' component={Videos} />
                <Route exact path='/codes' component={Codes} />
                <Route exact path='/blog' component={Blog} />
                <Route exact path='/about' component={About} />
                <Route exact path='/contact' component={Contact} />
            </Switch>
            <SpeedDial
                style={{ position: "absolute", right: '20px', bottom: '15px' }}
                ariaLabel="SpeedDial"
                color="secondary"
                icon={<ShareIcon />}
                onClose={() => toggleDial(false)}
                onOpen={() => toggleDial(true)}
                open={dial}
                direction='up'
            >
                {actions.map(action => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => { toggleDial(false); window.open(action.url, '_blank'); }}
                        className={action.name}
                    />
                ))}
            </SpeedDial>
            <SwipeableDrawer open={drawer} onClose={() => toggleDrawer(false)} onOpen={() => toggleDrawer(true)}>
                <MenuDrawer location={location} toggleDrawer={toggleDrawer} />
            </SwipeableDrawer>
        </div>
    </>
}

export default Main;
import React from 'react';
import CustomLink from '../CustomLink.js';
import Button from "./Button";

const Home = () => {
    return <div id="splash">
        <h1 style={{ display: 'none' }}>Ange Loron</h1>
        <div id="splash_bg" style={{ background: `url('https://res.cloudinary.com/angeloron/image/upload/v1586856943/042lion_portrait_fqdgwu.jpg') no-repeat 50%/ auto 100%` }}></div>
        <CustomLink to="/photos" id="homebutton">
            <Button text={'ENTER'} />
        </CustomLink>
    </div>
}

export default Home;
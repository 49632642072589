import React, { useState, useEffect } from "react";
import CustomLink from '../CustomLink.js';
import { menu_array } from "./MenuArray";
import { IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import './css/Menu.css';

const Menu = ({ location, toggleDrawer }) => {

    const [active, toggleActive] = useState('PHOTOS');

    useEffect(x => {
        const currentPage = location.pathname.replace('/main/', '').toUpperCase().replace('_', ' ');
        toggleActive(currentPage);
    }, [location]);

    return <div id="menu">
        <CustomLink to="/">
            <div className="menu_item">HOME</div>
        </CustomLink>
        {menu_array.map((x, i) => <CustomLink key={i} to={`/${x.name.toLowerCase().replace(' ', '_')}`}>
            <div className={`menu_item${active === x.name ? ' active' : ''}`} onClick={() => toggleActive(x.name)}>{x.name}</div>
        </CustomLink>)}
        <div id="mobileMenuToggler">
            <h1>Ange Loron</h1>
            <div>
                <IconButton onClick={() => toggleDrawer(true)} aria-label="delete" size="small">
                    <MenuIcon fontSize="large" />
                </IconButton>
            </div>
        </div>
    </div>
}

export default Menu;
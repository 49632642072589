import React from "react";
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter, faLinkedin, fa500px, /*faTumblr,*/ faYoutube, faGithub } from '@fortawesome/free-brands-svg-icons'
import Footer from '../Footer';
import useTypewriter from "./Typewriter";
import '../css/About.css';

const About = () => {

    const [typewriter] = useTypewriter(['Freelancer', 'Web Developer', 'Photographer',]);

    return <Scrollbars universal={true}>
        <div id="aboutmeWrapper">
            <img id="aboutmePhoto" src="/angeloron.jpg" alt="Ange Loron" />
            <div id="aboutmeBox">
                <h2>Hi, I'm Ange Loron and I am a <div id="aboutmeTypewriter">{typewriter}</div></h2>
                <div id="aboutmeShort" style={{ lineHeight: '28px', color: 'rgb(136, 136, 136)' }}>
                    <p>With 15 years of experience as a team leader and project manager, I have acquired the skills and knowledge necessary to not only make your "website" but to take the additional care to make your project a success.</p>

                    <p>As Herman Cain once said <i style={{ color: 'rgb(0, 82, 175)', fontSize: '16px' }}>"Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful."</i><br />
                        With the dedication and love that I have toward what I am doing, I will help you succeed.</p>
                    Find me elsewhere on the world wide web:
                    <div id="socialWrapper">
                        <div className="social" id="instagram" onClick={() => window.open('https://www.instagram.com/angeloronphoto/', '_blank')}>
                            <Icon id="instagramIcon" icon={faInstagram} />
                            <div id="instagramBackground" />
                        </div>
                        <div className="social" id="fivehundredpx" onClick={() => window.open('https://500px.com/angeloron', '_blank')}>
                            <Icon icon={fa500px} />
                        </div>
                        <div className="social" id="facebook" onClick={() => window.open('https://www.facebook.com/loronange', '_blank')}>
                            <Icon icon={faFacebookF} />
                        </div>
                        <div className="social" id="twitter" onClick={() => window.open('https://twitter.com/ange_loron', '_blank')}>
                            <Icon icon={faTwitter} />
                        </div>
                        <div className="social" id="linkedin" onClick={() => window.open('https://www.linkedin.com/in/ange-loron-13047050', '_blank')}>
                            <Icon icon={faLinkedin} />
                        </div>
                        {/* <div className="social" id="tumblr" onClick={() => window.open('https://www.tumblr.com/blog/imacange', '_blank')}>
                            <Icon icon={faTumblr} />
                        </div> */}
                        <div className="social" id="youtube" onClick={() => window.open('https://www.youtube.com/channel/UC7JUDc1VqGpR93F0CaOQRWg', '_blank')}>
                            <Icon icon={faYoutube} />
                        </div>
                        <div className="social" id="github" onClick={() => window.open('https://github.com/angeloron', '_blank')}>
                            <Icon icon={faGithub} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div id="aboutmeLong">
            <b>EARLY AGE</b>

            <p>Born and raised in Carribean, I first discovered the universe of computers at the age of 11 when I saw my father creating a website with Microsoft Frontpage.
            After several hours of just staring at what he was doing, my father involved me and I assisted him finishing that website.</p>

            <p>A year later, he got me my first computer with Windows 95. I then learned about the internet and started learning HTML, found the first "hacker" chalenges online and made my first full website (probably the most ugly thing I have ever done but it was mine and I loved it).</p>

            <p>I then discovered Windows 98, Windown Millenium, Windows 2000 and specialized in making skins for each of these operating systems.</p>

            <p>At 15 I moved to Paris, France to live with my mother and built my first computer with the newly released windows XP.
            After a year of hacking via MSN Messenger, spoofing IP addresses, etc. and downloadings thousands of medias through Kazaa, E-Mule or LimeWire.</p>

            <p>I figured it's more fun to use my skills to create secure websites with my own designs and that's when I learned about Photoshop 5.0.</p>

            <p>As I was living near the headquarter of Dassault System, I got a copy of Solidworks and discovered the world of 3D.</p>

            <p>At the age of 17 and after 5 years of learning more "at home" with my computer than "at school", I decided to quit school and make websites for a living.</p>

            <p>At 18, I was finally ready and moved to Copenhagen - Denmark to become a manager for 10 years.</p>

            <p>With my computer knowledge and experience in managing businesses, I now specialize in helping businesses to organize their production lines and create web applications tailor made to solve whatever problems that business runs into.</p>

            <p>For 5 years I have been making websites or web applications online and offline to solve whatever was needed for that specific business to take off. It is important to me to care to study one's business/project, layout how each lines are working (if needed create new lines, checklists, etc.) and work out what computer technology can be use to improve that business.</p>

            <p>In some cases, setting up a database with a simple log was enough to expand a business. Create a tailor made inventory software or just create a portfolio for a photographer. I've worked for individuals and companies with several hundreds staff, and we've always found the one thing needed to expand their activity.</p>

        </div>
        <Footer />
    </Scrollbars>
}

export default About;
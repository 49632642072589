import React, { useState } from "react";
import Axios from 'axios';
import Footer from '../Footer';
import ReCAPTCHA from "react-google-recaptcha";
import { Scrollbars } from 'react-custom-scrollbars';
import { TextField, Button } from "@material-ui/core";
import { SnackbarProvider, useSnackbar } from 'notistack';
import "../css/Contact.css"

const Contact = () => {

    const [name, loadName] = useState('');
    const [email, loadEmail] = useState('');
    const [message, loadMessage] = useState('');
    const [submit, submitForm] = useState(false);
    const [captchaValue, captchaChange] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const handlesubmit = e => {
        e.preventDefault();
        submitForm(true);
        if (name === '' || email === '' || message === ''|| !captchaValue) {
            enqueueSnackbar('Please fill up all fields', { variant: 'error' });
        } else {
            const data = { name, email, message };

            Axios.post('https://us-central1-portfolio-c333b.cloudfunctions.net/submit', data)
                .then(res => {
                    enqueueSnackbar('Your message was sent', { variant: 'success' });
                    setTimeout(() => {
                        loadName('');
                        loadEmail('');
                        loadMessage('');
                        submitForm(false);
                        captchaChange(null);
                    }, 1000);
                })
                .catch(error => {
                    console.log(error);
                    enqueueSnackbar('There was an error sending your message', { variant: 'error' });
                    captchaChange(null);
                });
        };

    }

    return <Scrollbars universal={true}>
        <form id="contactmeForm">
            <div id="contactmeHeader">CONTACT FORM</div>
            <div id="contactinputWrapper">
                <TextField
                    label="name"
                    variant="outlined"
                    value={name}
                    error={name === '' && submit}
                    onChange={e => loadName(e.target.value)}
                    required
                />
                <TextField
                    label="e-mail"
                    type="email"
                    variant="outlined"
                    value={email}
                    error={email === '' && submit}
                    onChange={e => loadEmail(e.target.value)}
                    required
                />
                <TextField
                    label="message"
                    variant="outlined"
                    value={message}
                    error={message === '' && submit}
                    onChange={e => loadMessage(e.target.value)}
                    rows="8"
                    multiline
                    required
                />
                <ReCAPTCHA
                    sitekey="6LeZaM0UAAAAAEkaFesx0rG9MLem_rZ6Dk_y-6v1"
                    onChange={captchaChange}
                    style={{margin: '0 auto'}}
                />
                <Button
                    disabled={!captchaValue}
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={e => handlesubmit(e)}
                >
                    SEND
                </Button>
            </div>
        </form>
        <Footer />
    </Scrollbars>
}

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={1}>
            <Contact />
        </SnackbarProvider>
    );
}
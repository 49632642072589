import React from "react";
import { ButtonBase } from '@material-ui/core'
import './css/Button.css';

const Button = ({ text }) => {
    return <ButtonBase id="button">
            <div id="button_text">
                {text}
            </div>
    </ButtonBase >
}

export default Button;
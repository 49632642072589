import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
    Container,
    Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { stripHtml } from '../../functions';
import { Scrollbars } from 'react-custom-scrollbars';
import Footer from '../Footer';

const useStyles = makeStyles({
    card: {
        maxWidth: 450,
        maxHeight: 400,
        margin: 20,
    },
    cardActionArea: {
        height: "100%",
        justifyContent: "start",
        display: "flex",
        flexDirection: "column",
    }
});

const Blog = () => {

    const [articles, loadArticles] = useState([]);
    const classes = useStyles();

    useEffect(x => {
        axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@angeloron')
            .then(function (response) {
                // handle success
                loadArticles(response.data.items);
                // console.log(response.data.items);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }, []);

    return <Scrollbars universal={true}>
        <Container>
            <Grid container justify="center">
                {articles.map((x, i) =>
                    <Card key={i} className={classes.card}>
                        <CardActionArea className={classes.cardActionArea} onClick={() => window.open(x.link, '_blank')}>
                            <CardMedia
                                component="img"
                                alt={x.title}
                                height="170"
                                image={x.thumbnail}
                                title={x.title}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2" style={{ height: "auto" }}>
                                    {x.title}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {stripHtml(x.description).split('Continue reading', 1)[0]}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                )}
            </Grid>
        </Container>
        <Footer />
    </Scrollbars>
}

export default Blog;